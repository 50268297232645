.news-page {
  font-family: 'Inter', sans-serif;
  color: #000;
  background-color: #fff;
  padding: 2rem;
  padding-top: 100px;

  .news-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }

  .news-tile {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &:hover .news-title {
      text-decoration: underline;
    }

    .news-image {
      width: 100%;
      height: 200px; // Set a fixed height for images
      object-fit: cover; // This will make sure images cover the area nicely
      display: block;
      transition: transform 0.3s ease;
    }

    .news-content {
      padding: 1rem;
      background: rgba(255, 255, 255, 0.9);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .news-date {
        font-size: 0.875rem;
        color: #666;
      }

      .news-title {
        font-size: 18px;
        margin-top: 0.5rem;
        transition: text-decoration 0.3s ease;
      }
    }
    news-type-label { // Style for the type label
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 5px;
      border-radius: 5px;
      font-size: 0.8em;
    }
  }
  .middle-title {
    text-align: center;
    font-size: 1.5em;
    margin: 2em 0;
    color: #2a7f62; // A color that stands out, adjust as needed
    font-weight: bold;
  }
  
  a {
    color: #007bff; // This is a common blue color for links
    text-decoration: none; // Removes underline from links
  
    &:hover {
      text-decoration: underline; // Adds underline on hover for better user interaction
      color: #0056b3; // Darkens the color on hover
    }
  }

  .filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  
    button {
      margin: 0 10px;
      padding: 10px 20px;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      cursor: pointer;
  
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
  .blog-header{
    font-size: 37px;
    font-weight: 800;
    // margin-top: 18px;
    // padding-left: 3%;
    // text-align: center;
    
  }
}

@media only screen and (max-width: 640px) {
  .news-page {
    .news-container {
      grid-template-columns: 1fr;
    }
  }
}
