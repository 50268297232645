.landing-page__footer {
  background: black;
  //   background-color: lightness($color: #011100);
  //   opacity: 100%;
  // border-color: #fec200;
  height: 164px;
  // border-color: #fec200;
}
.footer__r1 {
  padding: 20px;
  text-align: center;
  //   border-top: solid 2px #fec200;
}
.footer {
  // border-top: 2px solid #faf9f8;
}
.footer__r2 {
  padding: 20px;
  text-align: center;
}

.social__icons {
  display: inline-block;
  border-bottom: solid 1px rgba(255, 255, 255, 0.363);
}
.icon-social {
  margin: 10px;
  color: white;

  //   font-size: 12px;
}
.copyright__items {
  display: inline-block;
  margin: 10px;
  text-align: center;
  color: white;
}
a{
  color: rgba(255, 255, 255, 0);
}