@import "./_mixins.scss";
.contact_details {
  padding: 40px;
}
.yd__img {
  height: 400px;
  width: 100%;
  padding-bottom: 20px;
  // padding-top: 20px;
}
.contact-page {
  background-color: #f5f5f7;
}
.contact__form {
  padding: 40px;
  background-color: white;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.contact__form:hover {
  /* On mouse-over, add a deeper shadow */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.googleMap {
  width: fit-content;
}
.submit__btn {
  float: right;
  background: black;
}
.contact-page__heading {
  // padding: 80px;

  // color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  color: white;
  // padding-left: 120px;
  // margin-right: 120px;

  padding-top: $spacing-12;
  padding-bottom: $spacing-03;
}


// @media screen and (min-width: 1400px) {
//   .contact-page__banner {
//     min-height:500px;
//   }
// }
// @media screen and (min-width: 1600px) {
//   .contact-page__banner {
//     min-height: 500px;

//   }
// }
// @media screen and (min-width: 1900px) {
//   .contact-page__banner {
//     min-height: 500px;

//   }
// }

@media screen and (max-width: 600px) {
  .contact-page__banner {
    // overflow-x: hidden;
    // padding-top: $spacing-07;
    // padding-bottom: $spacing-07 * 4;
    @include contact-page-background-mobile;
    min-height: 650px;
    padding: 0;
    // margin: 0;
    min-width: 0;
    overflow: hidden;
  
    // padding: 0px;
    max-width: 100%;
    max-height: 100%;
  }
}
@media screen and (min-width: 735px)    {
  .contact-page__banner {
    // overflow-x: hidden;
    // padding-top: $spacing-07;
    // padding-bottom: $spacing-07 * 4;
    @include contact-page-background;
    min-height: 650px;
    padding: 0;
    // margin: 0;
    min-width: 0;
    overflow: hidden;
  
    // padding: 0px;
    max-width: 100%;
    max-height: 100%;
  }


}

.contact-page__heading {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 190px;
  font-weight: 700;
  font-size: 50px;
  font-family: 'inter';
}
.contact__ul li {
  padding: 2px;
}

// div.bx--col-lg-16.contact-page__banner{
//   min-height: 650px;
// }
.career__heading{
  background-color: #F2F2F2;
  margin-bottom: 20px;

}
a.bx--header__menu-item{
  font-family: "inter";
}