@mixin about-page-background() {
  // background: #00c9ff;
  // background-image: linear-gradient(
  //   225deg,
  //   #34e89e 0%,
  //   #0f3443 100%,
  //   #625eb1 100%
  // );
  // background-image: url("../../assets/bgs/avacado.jpg");
  background-size: cover;
  height: auto;
  width: 100%;
  position: relative;
  &::before {
    content: "";
    position: fixed;
    // left: -$spacing-05;
    top: 0;
    // right: -$spacing-05;
    bottom: 0;
    background: $ui-03;
    z-index: -2;
    // opacity: 70%;
  }
}
