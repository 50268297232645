@mixin contact-page-background() {


  background-image: url("../../assets/imgs/contactbanner.webp");
  background-size: cover;
  

}
@mixin contact-page-background-mobile() {


  background-image: url("../../assets/bgs/contactbanner-mobile.webp");
  background-size: cover;
  

}
