@import "./_mixins.scss";


.h2 {
  padding: 20px;
  text-align: center;
}

.about-page__banner {
  @include about-page-background;
  min-height: 300px;
  padding: 0;
  // margin: 0;
  min-width: 0;
  overflow: hidden;
  margin: 0;

  // padding: 0px;
  max-width: 100%;
  max-height: 100%;
}
.icon_values {
  color: white;
  padding-bottom: 20px;
}

.about-card {
  // margin-top: $spacing-09;
  display: flex;
  margin: 2px;

  //   flex-direction: column;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  //   padding-bottom: 80px;
  min-height: 150px;
  max-width: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  //   background-color: #fec200;
  justify-content: center;

  // top border in only small breakpoints to prevent overrides
  @include carbon--breakpoint-down(md) {
    &:not(:nth-child(2)) {
      border-top: 1px solid $ui-03;
      padding-top: $spacing-09;
    }
  }
  // left border in just the 2nd column items
  @include carbon--breakpoint(md) {
    &:nth-child(odd) {
      // border-left: 1px solid $ui-03;
    }
  }
  // left border in all items
  @include carbon--breakpoint(lg) {
    margin-top: 1;
    // border-left: 1px solid $ui-03;
    // border-right: 1px solid $ui-03;

    svg {
      margin-top: $layout-06;
    }
  }
}
.about-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.about-card__heading {
  // @include carbon--type-style("productive-heading-03");
  text-align: center;
  padding-top: 40px;
}
.about-card__body {
  margin-top: $spacing-06;
  flex-grow: 1; // fill space so icons are bottom aligned
  flex-basis: 0;
  // @include type-style("body-long-01");
  // prevent large line lengths between small and medium viewports
  // @include carbon--breakpoint-between(321px, md) {
  //   max-width: 75%;
  // }
}

.human {
  background-color: rgb(4, 4, 27);
}

@mixin about-page-background() {

  background-image: url("../../assets/sketch/hero/about-hero2.svg");

  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  min-width: 100%;

}

@mixin about-page-background-mobile() {

  // background-image: url("../../assets/bgs/about-hero-mobile.webp");

  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  // min-width: 100%;
  

}

.about-page__banner{
    
    min-height: 400px;
padding: 0;
// margin: 0;
min-width: 0;
overflow: hidden;

// padding: 0px;
max-width: 100%;
max-height: 100%;
min-height: 700px;
}

@media screen and (min-width: 735px)    {
  .about-page__banner {
         min-height:900px;
         @include about-page-background();
        
     }
     .aboutt-us--title{
      font-size: 45px;
      font-weight: 800;
      text-align: center;
      padding: $spacing-09;
        color:rgb(2, 2, 2);
        padding-left: 25%;
        padding-right: 25%;
        padding-top: 15%;
    
    
    
    }
    .aboutUs {
      padding: 60px;
      margin: 0;
    }
    .mission{
      font-size:45px;
      text-align: center;
      padding-left: 20%;
      padding-right:20%;
      }
      .problem{
  font-size: 16px;
  font-weight: 400;
}
.aboutUs__cards {
  flex: 1;
  padding: 60px;
  justify-content: center;
  align-items: stretch;
}
.ourteam--row { 
  background-color: white;
padding: 60px;
}
.our--network{
  padding-left: 0%;
}
}

@media screen and (max-width: 600px) {
  .about-page__banner {
    min-height:600px;
    
    @include about-page-background-mobile();
   
}
.aboutt-us--title{
  font-size: 27px;
  font-weight: 600;
  text-align: center;
  // padding: $spacing-09;
    color:rgb(2, 2, 2);
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 45%;
}

.ttl--about--pg{
  font-size: 26px;
  padding-left: 3%;
padding-right:3%;
}
.dash{
  padding-left: 3%;
  padding-right:3%;
}

.aboutUs {
  padding-left: 5%;
  padding-top: 10%;
  margin: 0;
}

 .mission{
font-size:25px;
// text-align: center;
padding-left: 3%;
padding-right:3%;
padding-bottom: 13%;
}

.problem{
  font-size: 16px;
  font-weight: 400;
  padding-left: 3%;
  padding-right:3%;

}

.about--img{
  display: block;
    margin-left: auto;
    margin-right: auto;
}
.aboutUs__cards {
  flex: 1;
  padding-left: 5%;
  justify-content: center;
  align-items: stretch;
}
.ourteam--row { 
  background-color: white;
padding-left: 5%;
padding-top: 10%;
margin-top: 3%;
}
.our--network{
  padding-left: 0%;
}
}
// @media screen and (max-width: 600px) {
//   .about-page__banner {
//   background-image: url("../../assets/bgs/hero-about-sm2.svg");

//   }
// }
// @media screen and (min-width: 1400px) {
//   .about-page__banner {
//     min-height:900px;
    
//   }
// }
// @media screen and (min-width: 1600px) and (max-width: 2000px) {
//   .about-page__banner {
//     min-height: 900px;
//     background-image: url("../../assets/bgs/hero-about-lg2.svg");

//   }


  
// }

// @media screen and (min-width: 2001px) and (max-width: 5000px) {
//   .about-page__banner {
//     // min-height: 1400px;
//     background-image: url("../../assets/bgs/hero-about-lg3.svg");
//     .aboutt-us--title{
//       font-size: 57px;
//       font-weight: 800;}

//   }

// }
// @media screen and (min-width: 1900px) {
//   .about-page__banner {
//     min-height: 900px;

//   }
// }


.mini-about-title{
  margin-top: 104px;
  color:#000000 ;  
  font-weight: 400;
  text-align: center;
  font-size: 20px;
}

.about-us-grid{
  margin-bottom: $spacing-05;
  background-color: #F2F2F2;
  // padding: 60px;
}

h4.names{
  // text-align: center;
  padding: 6px;
  font-weight: 500;
  padding: 5px;
}

.mini-names{
  // text-align: center;
  font-weight: 600;
  padding: 6px;
  color: #535353;
}

div.bx--col-lg-16.aboutUs{
  // text-align: center;
  background-color: #F2F2F2;
  margin-top: 10px;
}

.aboutUs__heading{
  font-size: 37px;
  font-weight: 600;
  // line-height: 44.5px;
}





.bx--row .value--row{
  background-color: #F2F2F2;

}



