.bx--col-lg-8 .career__container {
  margin: 0;
  padding: 0;
}
.career-section {
  padding: 60px;
  justify-content: center;
}
.career-section__heading {
  // @include carbon--type-scale(6);
  text-align: center;
  font-size: 42px;
  padding-top: $spacing-08;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 40px;
}

.career-card:hover {
  /* On mouse-over, add a deeper shadow */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.career-card__heading {
  // margin-top: 10px;
  padding: 20px;
  text-align: center;
  //   font-size: large;
  color: black;
  background-color:#e4e1d8;
  // opacity: 0.7;
  min-height: 200px;
  width: 100%;
}

.career-card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  //   margin: 40px 40px 40px 40px;
  // margin: 60px;
  //   flex-wrap: wrap;
  width: 320px;
  height: auto;
  margin: 30px;
}
.career-card:hover {
  /* On mouse-over, add a deeper shadow */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.bx--row.career-section {
  margin: 0;
}

.btn_apply {
  padding: 20px;
  text-align: center;
}
