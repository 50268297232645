@import "./override";
.hamburger {
  display: none;
}
.Side_menu {
  display: none;
}

@media screen and (max-width: 40em) {
  .hamburger {
    display: block;

  }
  .Side_menu {
    display: block;
 
  }
}
