@mixin landing-page-background() {
  
  // min-height: 1200px;
  // width: 100%;

  /* Location of the image */
  // background-image: url("../../assets/sketch/hero/Screen-hero-lg-desktop.jpg");
  // background-image: url("../../assets/sketch/Screen-hero-lg-1-new.jpg");
  
  z-index: -2;
  // background-image: url("../../assets/bgs/main-hero-lg1.jpg");
  // background-image: url("../../assets/bgs/LP22-1.webp");

  // background: linear-gradient(to right, rgba(3, 93, 32, 0.2),rgba(28, 224, 7, 0.2));
  // background-image: linear-gradient(to right top , #041837, #03324e, #055f67, #00bf72, #a8eb12);
  // background-image: linear-gradient(to right, #053718, #00453f, #00508f, #0054e0, #121aeb);
  // background-image: linear-gradient(to right, #2aed0f, #95c200, #bb9500, #c86500, #c13310);
  // @keyframes gradient {
  //   0% {
  //     background-position: 0% 50%;
  //   }
  //   50% {
  //     background-position: 100% 50%;
  //   }
  //   100% {
  //     background-position: 0% 50%;
  //   }
  // }
  // animation: gradient 9s ease infinite;
  /* Position and center the image to scale nicely on all screens */
  background-repeat: no-repeat;
  background-size: cover;
  //position: relative;
  right: 0;
  min-width: 100%;
  
  
  }

  @mixin problem-background() {
    z-index: -2;

    
    }

  @mixin problem-background2() {
    z-index: -2;
 
    background-image: url("../../assets/imgs/vpdisease.webp");
  
    
    }

    @mixin yield-background() {
      z-index: -2;
      

      background-image: url("../../assets/imgs/tomcov.webp");

      
    
    
      /* Position and center the image to scale nicely on all screens */
      background-repeat: no-repeat;
      background-size: cover;
      //position: relative;
      right: 0;
      min-width: 100%;
      // height: 1000px;
      
      }
  

  @media screen and (min-width: 45em) {
    // background-image: url("../../assets/bgs/greenhouse1.png");
    // background-color: rgba(0,0,0,0.5);
    // .hero-video {
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   width: 100%;
    //   height:auto;
    //   // top:auto;
    //   object-fit: cover;
    //   z-index: -10;
    //   overflow: hidden;
    
    // }
    // @mixin landing-page-background(){
    //   background-image: url("../../assets/bgs/hero-banner-sm.png");

    // }
    
  }
  @media screen and (max-width: 44em) {
    // background-image: url("../../assets/bgs/greenhouse1.png");
    // .hero-video {
    //   position: absolute;
    //   overflow: hidden;
    //   right: 0;
    //   top: 0;
    //   width: auto;
    //   height:100%;
    //   // top:auto;
    //   object-fit: cover;
    //   z-index: -10;
    //   overflow: hidden;
    
    // }

  }

