// BlogPostTemplate.scss
.blog-post {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  
    .blog-post-title {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  
    .blog-post-metadata {
      font-size: 0.9rem;
      color: #666;
      margin-bottom: 20px;
    }
  
    .blog-post-image {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  
    .blog-post-content {
      line-height: 1.6;
    }
  }
  .blog-post-share {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  blockquote {
    border-left: 4px solid #2a7f62;
    padding-left: 1em;
    margin: 1em 0;
    color: #555;
    background-color: #f9f9f9;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
      color: #0056b3;
    }
  }  
  .back-button {
    // Example styling
    background-color: #f5f5f5;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
  }
  