.example-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.container-slideshow{
  width: 100vw;
  height: 100vh;
  background: #151515;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
  .imgStyle{
    background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  right: 0;
  min-width: 100%;
  }
  .next,
  .prev {
    visibility: hidden;

    // top: calc(50% - 20px);
    position: relative;
    padding: 50px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 80px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: 800;
    
    font-size: 38px;
    z-index: 2;
  }
  
  .next {
    right: 0px;
    float: right;
  }
  
  .prev {
    left: 10px;
    transform: scale(-1);
  }
  
  img {
    // position: absolute;
    max-width: 100%;
    height: auto;
  }
  
  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }