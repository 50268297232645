// BlogPost4.scss

.blog-post {
    .post-image {
      text-align: center;
      margin-bottom: 20px;
  
      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  
    .post-content {
      margin-bottom: 40px;
  
      p {
        margin-bottom: 20px;
      }
  
      ul {
        margin-bottom: 20px;
        padding-left: 20px;
  
        li {
          margin-bottom: 10px;
        }
      }
  
      blockquote {
        border-left: 4px solid #2a7f62; /* Adjust the color to match your theme */
        padding-left: 1em;
        margin: 1em 0;
        color: #555;
        background-color: #f9f9f9;
        font-style: italic;
        font-size: 1.3em; /* Larger font size */
        line-height: 1.4; /* Adjust line height for better readability */
        border-radius: 5px; /* Slightly round the corners */
        padding: 15px; /* Add some padding */
      }
    }
  
    .post-footer {
      text-align: center;
      margin-top: 40px;
  
      .footer-image {
        margin-bottom: 20px;
  
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
  