.middle-title {
    text-align: center;
    font-size: 1.5em;
    margin: 2em 0;
    color: #2a7f62; // A color that stands out, adjust as needed
    font-weight: bold;
  }
  a {
    color: #007bff; // This is a common blue color for links
    text-decoration: none; // Removes underline from links
  
    &:hover {
      text-decoration: underline; // Adds underline on hover for better user interaction
      color: #0056b3; // Darkens the color on hover
    }
  }