.info-section {
  // justify-items: center;
  // padding: 60px;
  // margin: 10px;
}

.info-section__heading {
  // @include carbon--type-scale(6);
  text-align: center;
  padding-bottom: $spacing-06;
  font-size: 42px;
  // padding-left: 60px;
  // padding-right: 60px;
}
.info-section__subheading {
  // color: #8d8d8d;
  // padding-top: $spacing-03;
  margin-bottom: 30px;
  text-align: center;
  // padding-left: 30px;
  // padding-right: 30px;
}
.info-card {
  // margin-top: $spacing-09;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  // top border in only small breakpoints to prevent overrides
  @include carbon--breakpoint-down(md) {
    &:not(:nth-child(2)) {
      border-top: 1px solid $ui-03;
      padding-top: $spacing-09;
    }
  }
  // left border in just the 2nd column items
  @include carbon--breakpoint(md) {
    &:nth-child(odd) {
      // border-left: 1px solid $ui-03;
    }
  }
  // left border in all items
  @include carbon--breakpoint(lg) {
    margin-top: 1;
    // border-left: 1px solid $ui-03;
    // border-right: 1px solid $ui-03;

    svg {
      // margin-top: $layout-06;
    }
  }
}

@media screen and (max-width: 600px) {

  .info-card{
    margin-top: 5%;
    margin-bottom: 5%;

  }
}
.info-card:hover {
  box-shadow: 0 8px 16px 0 rgba(22, 132, 57, 0.8);
}
.info-card__heading {
  // @include carbon--type-style("productive-heading-03");
  text-align: center;
}
.info-card__body {
  margin-top: $spacing-06;
  // flex-grow: 1; // fill space so icons are bottom aligned
  // flex-basis: 0;
  text-align: left;
  // @include type-style("body-long-01");
  // prevent large line lengths between small and medium viewports
  // @include carbon--breakpoint-between(321px, md) {
  //   max-width: 75%;
  // }
}

.icon__what {
  // color: #fec200;
  text-align: center;
  // font-size: 6em;
  padding-bottom: 20px;
}
