.container__header {
  // position: fixed;
}
.bx--header {
background-color: rgba(0, 0, 0, 0.182);
// background-color: white;

// color: black;
border: none;
padding-left: 60px;
font-weight: 800;
font-size: 30px;
position:absolute;
height: 10%;
}
@media screen and (max-width: 600px) {
  .bx--header {padding-left: 1px;}
}
  .bx--header__menu-toggle{
  margin-top:10px;
}
.bx--header__nav{
  
}
.bx--header__name {
    // font-family: "Russo One", sans-serif;


  color: rgb(5, 5, 5);
}

.bx--header__nav::before {
  // font-family: CircularStd;
  font-weight: 600;
  border-color: #050505;
  // background-color: #fec200;
  opacity: 0%;
}
.bx--text-truncate--end {
  // font-family: CircularStd;
  color: rgb(252, 250, 250);
  font-size: 16px;
  font-weight: 500;
}

.logo--brand {
  // width: 34px;
  // height: 30px;
  top:0%;
  // margin-left: 20px;
  // margin-right: 20px;
  // margin-top: 25px;
  // margin-bottom: 20px;
}
.bx--header__nav{
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.care{
  color:#0A51FF
}

button.btn-book{
    background-color: #099A4D;
    margin: 0%;
    text-align: center;
      
    // line-height: 65px;  
    // border-radius: 2cm;
}
button.btn-book:hover{
  background-color: #064925;

}
.btn-book{
    font-weight: 600;
    font-size: 18px;
    text-align: center;

}

.bx--header__menu-item option:hover{
background-color: #099A4D;
}

a.haslink{
  margin-bottom: 0%;
  padding-bottom: 0%;
  padding-bottom: 0%;
  padding-top: 1%;
}
.bx--header__global{
  height: auto;
  padding-right: 3%;
}

a.bx--header__menu-item:hover{
  background-color: rgba(0, 0, 0, 0.185);

}

a.bx--header__menu-item.bx--header__menu-title {
  color: white;
  font-size: 16px;
}