.partners__logo {
  // position: relative;
  // max-height: 120px;
  // max-width: 100%;
  // height: auto;
  // justify-content: center;
  max-width: 100%;
  max-height: 80%;
  display: block; /* remove extra space below image */
  text-align: center;
  display: block;
  overflow: hidden;
}

.partner-card {
  // // margin: 0;
  padding: 0;

  // //   margin: 40px 40px 40px 40px;
  margin: 60px;
  // //   flex-wrap: wrap;
  // width: 300px;
  // height: 200px;
  padding-top: 10px;
}
.partner-section {
  // padding: 60px;
  // text-align: center;
  // flex-grow: 1;
  // padding: 20px;
}
.partner-section__heading {
  // @include carbon--type-scale(6);
  // text-align: center;
  font-size: 42px;
  padding-top: $spacing-08;
  // padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 10px;
}
