@import "./_mixins.scss";
@import "./_overrides.scss";
// .bx--row {
//   border: 2px solid
//     linear-gradient(225deg, #34e89e 0%, #0f3443 100%, #625eb1 100%);
//   transition: border-width 0.6s linear;
// }
// .bx--row:hover {
//   border-width: 10px;
// }
.bx--grid {
  padding: 0;
  // margin: 0;
  // font-family: CircularStd-Bold;
  min-width: 0;
  min-height: 300px;
  overflow: hidden;

  // padding: 0px;
  max-width: 100%;
  max-height: 100%;
}
.landing-page__illo {
  // max-width: 100%;
  // max-height: 100%;

}
.landing-page__banner {
  // overflow-x: auto;
  padding-top: $spacing-09*2;
  padding-bottom: $spacing-08 * 6;
  // @include landing-page-background;
  // margin-bottom: 0;
  margin-bottom: $spacing-05;

  

  @include landing-page-background();

}

@media screen and (max-width: 600px) {
  .landing-page__banner {
  // background-image: url("../../assets/bgs/main-hero-lg1.jpg");
   
  }
}
@media screen and (min-width: 0px) {
  .landing-page__banner {
    min-height:500px;
  }
}
@media screen and (min-width: 735px) {
  .landing-page__banner {
    min-height: 650px;

  }
}
@media screen and (min-width: 169px) {
  .landing-page__banner {
    min-height: 792px;

  }
}


.landing-page__tab-content {
  padding-top: $layout-05;
  padding-bottom: $layout-05;
  // padding-left: 120px;
}
.landing-page__subheading {
  // @include carbon--type-style("productive-heading-05");
  // @include carbon--font-weight("semibold");
}
.landing-page__p {
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;

  // @include carbon--breakpoint-between((320px + 1), md) {
  //   max-width: 75%;
  // }
}
.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  // font-family: CircularStd-Bold;

  // padding-left: 120px;
  // padding-right: 20px;
}
.landing-page__label {
  // @include carbon--type-style("heading-01");
  text-align: left;
}

// .bx--tabs {
//   @include landing-page-background;
// }
.landing-page__banner {
  // height: auto;
  // margin-bottom: 0px;
  // position: relative;
  // min-height: 8900px;
}

.landing-page--pattern {
  // padding-left: 0;
  // width: 100%;
  // height: auto;
  // margin: 20px;
}
.landing-page__cta {
  // margin-left: 120px;
}

// .landing-page__device {
//   width: 100%;
//   height: auto;
// }
