.news-section__heading {
  // @include carbon--type-scale(6);
  text-align: center;
  font-size: 42px;
  padding-top: $spacing-08;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 40px;
}
.news-card {
}

.news__img {
  left: 0;
}

.news-card__heading {
  margin-top: 40%;
  padding: 2px 16px;

  //   font-size: large;
  color: white;
  background-color: #151515;
  font-size: small;
}

.read_more__link {
  //   padding: 20px;
  //   float: right;
  //   font-size: 10px;
  //   color: grey;
}
.bx--col-lg-4 .news__container {
  margin: 0;
  padding: 0;
}
.news-card {
  // margin: 0;
  padding: 0;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  //   margin: 40px 40px 40px 40px;
  margin: 60px;
  //   flex-wrap: wrap;
  width: 320px;
  height: 200px;
}
.news-card:hover {
  /* On mouse-over, add a deeper shadow */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
