.product-page .bx--row {

}
video.hero-video{
  content: "";
  // width: 100%;
  // margin-left:0;
  position: relative;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: transparent  no-repeat;
  background-size: cover;
  z-index: -1;
}
.overlay {
background:rgba(32, 38, 57,0.5) ;
// background: linear-gradient(100.1deg, rgba(32, 38, 57,0.4) 31.4%, rgba(63, 119, 87, 0.9) 70.2%);  z-index: 3;
}
// .hero-video:before {
//   content:"";
//   position: absolute;
//   top:0;
//   right:0;
//   left:0;
//   bottom:0;
//   z-index:2;
//   background:linear-gradient(to right,rgba(65, 0, 255, 1),rgba(255, 0, 232, 0.3));
// }
.header-content {
  content: "";
  z-index: 3;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  // margin: 0 auto;
  color: white;
  // padding-left: 30%;
  padding-right: 30%;
  // margin-top: 20%;
  text-align: center;

}


.video-container{
  width: 100%;
  height: 100%;
  max-height: 440px;
  max-width: 960px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
}
.bx--col-lg-8.bg--farmer{
  // background-image: url("../../assets/imgs/robot-prod.webp");
  min-height: 600px;
width: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  right: 0;}

  .bx--col-lg-8.bg--greenhouse{
    // background-image: url("../../assets/imgs/LEAFG.webp");
    min-height: 600px;
  width: 100%;
  
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;}
    .bx--col-lg-8.bg--robo{
      // background-image: url("../../assets/imgs/robot-prod.webp");
      min-height: 600px;
    width: 100%;
    
      background-repeat: no-repeat;
      background-size: cover;
      right: 0;}
// .Technology--title{
//     font-weight: 800;
//     text-align: center;
//     padding: $spacing-09;
//       color:rgb(2, 2, 2);
  
//   }
  
//   .mini-about-techno{
//     margin-top: 104px;
//     color:#0A51FF ;  
//     font-weight: 200;
//     text-align: center;
//     font-size: 24px;
//   }