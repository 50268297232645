@mixin tactile-page-background() {
    // background: #00c9ff;
    // background-image: linear-gradient(
    //   225deg,
    //   #34e89e 0%,
    //   #0f3443 100%,
    //   #625eb1 100%
    // );
    // background-image: url("../../assets/sketch/Tactile1.jpg");
    background-size: cover;
    
    
    // height: cover;
  
    // // display: block;
    // &::before {
    //   content: "";
    //   position: fixed;
    //   // left: -$spacing-05;
    //   top: 0;
    //   // right: -$spacing-05;
    //   bottom: 0;
    //   background: $ui-03;
    //   z-index: -2;
    //   // opacity: 70%;
  
    // }
  }

  .tactile-page__banner{
      @include tactile-page-background();
      min-height: 400px;
  padding: 0;
  // margin: 0;
  min-width: 0;
  overflow: hidden;

  // padding: 0px;
  max-width: 100%;
  max-height: 100%;
  min-height: 700px;
  }
  @media screen and (min-width: 1400px) {
    .tactile-page__banner {
      min-height:900px;
    }
  }
  @media screen and (min-width: 1600px) {
    .tactile-page__banner {
      min-height: 900px;
  
    }
  }
  @media screen and (min-width: 1900px) {
    .career-page__banner {
      min-height: 900px;
  
    }
  }
  