fieldset.bx--fieldset{
    padding: 20px;
}

form.bx--form.contact__form{
    padding: 0;
}



label.bx--label{
    color: #080808;
    padding:5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.bx--btn.sub-btn {
    // float:left;
    margin-top:15px;
    margin-right:0;
    font-size: 18px;

 margin-left:0% ;   

}

.small-form{padding-left: 5%; padding-right: 5%;}
