.product-section__heading {
  // @include carbon--type-scale(6);
  text-align: center;
  font-size: 42px;
  padding-top: $spacing-08;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 40px;
}
.tabs-solution {
  padding: 60px;
}
.product-section__title {
  // @include carbon--type-scale(6);
  // text-align: center;
  font-size: 32px;
  padding-top: $spacing-08;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;
}
.feature__icons {
  padding-right: 10px;
  // color: #fec200;
}
// .product-section__solution {
//   color: #2e2d2e;
//   padding-top: $spacing-03;
//   padding-left: 60px;
//   padding-right: 60px;
//   //   text-align: left;
//   //   margin-right: $spacing-09;
// }
.product-section__features li {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;
  font-size: 20px;
}
.video__product {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 10px;

  max-width: 100%;
  max-height: 500px;
  padding-top: $spacing-09;
}

.hardware-section__img {
  position: relative;
  padding-right: 60px;
  padding-left: 60px;
  max-width: 100%;
  max-height: 400px;
  padding-top: 20px;

  //   margin: 80px;
}
.software__feature {
  /* Add shadows to create the "card" effect */
  box-shadow: 1 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  //   margin: 40px 40px 40px 40px;
  padding-left: 60px;
  padding-right: 60px;
}
.robot__feature {
  /* Add shadows to create the "card" effect */
  box-shadow: 1 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  //   margin: 40px 40px 40px 40px;
  padding-left: 60px;
  padding-right: 60px;
}

.software__feature:hover {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  //   margin: 40px 40px 40px 40px;
  // padding: 20px;
}
