@import "./_mixins.scss";
.landing-page__r2 .bx--tabs__nav {
  right: 0;
}

.bx--content {
  // font-family: CircularStd-Bold;

  padding: 0px;
}

@media screen and (min-width: 735px)  and  (max-height:1441000px)  {
  .hero-video2{
    display: none;
  }
  .heading__vision{
    font-size: 72px;
    font-weight: 600;
    text-align: center;

  }

  .solu-header{
    font-size: 57px;
    font-weight: 800;
    // text-align: center;
    margin-top: 18px;
    padding-left: 20%;
    // padding-right: 20%;
    // color:white
    
  
  }
  .solu-mini{
    padding-top: 5%;
    padding-left: 20%;
    color: #625e5d;
  }
  .parag--solu{
    color: #625f5f;
    padding-left: 14%;
    padding-right: 20%;
    padding-top: 8%;
    font-size: 25px;
    font-weight: 400;
    line-height: 33px;
  }

  .parag--problem{
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 10px;
  
  
  }
  .feat--ttl{
    font-size: 37px;
    font-weight: 600;
    text-align: left;
    line-height: 44.5px; 
    // padding-top: 10%;
    padding-left: 20%;
    padding-bottom: 3%;
    letter-spacing: 0px;
    }

    .feat--parag{
      padding-right: 10%;
    padding-left: 20%;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    }

    .feat--cta{
      text-align: left;
      left: 20%;
      font-size: 14px;
      font-weight: 200;
      // margin-top: 3%;
    
    }

    .cta__book{
      font-size: 34px;
      font-weight: 700;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 5%;
      padding-top: 4%;
      text-align: center;
      
    }
    .feat--book{
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.4px;
      }
      .titl--desk{
        color: #F2F2F2;
        font-size: 47px;
        font-weight:800 ;
        line-height: 71.25px;
        // text-align: center;
        // margin-top: 3%;
        padding-left: 20%;
        padding-top: 5%;
        
        }
        
        .decision--sec{
          padding-top: 3%;
          padding-right: 3%;
          background-color: #032a1b;
        
        }
        div.bx--grid.contact--banner--grow{
          margin-bottom: 1%;
        }
        .feat--ttl--form{
          font-size: 37px;
        font-weight: 600;
        text-align: center;
        line-height: 44.5px; 
        padding-top: 3%;
        // padding-left: 10%;
        padding-bottom: 3%;
        letter-spacing: 0px;
        // background-color: #F2F2F2;
        // padding: 10%;
        }
  // .image-skout-sec{
  //   min-height: 770px;
  // }
  .landing-page__heading {
    font-size: 90px;
    font-weight: 900;
    float: none;
    margin-left: 20%;
    margin-bottom: 30px;
    margin-top: 23%;
    // position: absolute;
    // top: 40%;
    // left:50%;
    // transform: translate(-50%, -50%);
    // text-align: center;
  }

  .landing-page__sub-heading {
    font-size: 36px;
    font-weight: 400;
    float: none;
    margin-left: 14%;
    line-height: 44px;
    // position: absolute;
    // top: 60%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // font-style: italic;
  }
  
.value--prop{
  padding-bottom: 1%;
}
  .features__ul li {
    font-size: 25px;
  }
  .prob-open{
    font-size: 36px;
    line-height: 30px;
  }
  .bg__small{
    // background-color: #15680fda;
    min-height: 300px;
    background-image: url("../../assets/bgs/robotbg.webp");
    
    color: #F2F2F2;    
          
       margin-top: 10px;
        
    /* Position and center the image to scale nicely on all screens */
    background-repeat: no-repeat;
    background-size: cover;
    //position: relative;
    right: 0;
    min-width: 100%;
    }

  
}
@media screen and (min-width: 2257px) and (max-width:4256px) and (max-height:1441000px) and (min-height:776px) {
  .heading__vision{
    font-size: 72px;
    font-weight: 600;
    text-align: center;

  }

  .landing-page__banner{
min-height: 650px;
  }
  .landing-page__heading {
    font-size: 90px;
    font-weight: 900;
    float: none;
    margin-left: 20%;
    margin-bottom: 30px;
    margin-top: 20%;
    // position: absolute;
    // top: 40%;
    // left:50%;
    // transform: translate(-50%, -50%);
    // text-align: center;
  }

  .landing-page__sub-heading {
    font-size: 36px;
    font-weight: 400;
    float: none;
    margin-left: 14%;
    // position: absolute;
    // top: 60%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // font-style: italic;
  }
  

  .features__ul li {
    font-size: 25px;
  }
  .prob-open{
    font-size: 36px;
  }
  
}
@media screen and (max-width: 44em) {
  body {
    padding: 0;
    // margin: 0;
    // font-family: CircularStd-Bold;
  }
  .charts {
  
    padding-left: 0;
    padding-right: 0;
    padding-top: $spacing-05;
    padding-bottom: $spacing-05;
  }
  .landing-page__heading {
    font-size: 55px;
    font-weight: 900;
  border-color: #8DFF00;

    // text-align: center;
  }
  .landing-page__sub-heading li {
    font-size: 40px;
    font-weight: 400;
  }

}

.banner__usp {
  color: #fec300;
}
.landing-page__heading {
  // padding-left: 60px;
 // padding-top: 60px;
  text-align: center;
  //  background-color: rgba($color: #0b0bf0, $alpha: 0.5);
  // margin-right: 60%;
  // margin-left: 60px;
  // color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  color: rgb(255, 253, 253);
  // padding-left: 120px;
  // margin-top: 0%;

  // padding-top: $spacing-09;
  // padding-bottom: $spacing-03;
}

.landing-page__sub-heading {
  color: rgb(252, 250, 250);
  padding-left: 60px;
  padding-right: 60px;

  // padding-right: 120px;

  padding-top: $spacing-03;
  // padding-bottom: $spacing-05;
  padding-bottom: $spacing-06*2;
}
.know-more{
  position: absolute;
  bottom:60px;
  padding-left: 60px;
  color: black;
  }
  .know-more > p{
    font-weight: 100;

  }

.cta-demo{
  padding-left: 60px;
}

.bx--btn {
  // font-size: 15px;
  // text-align: center;
  margin: 2%;
  float: center;
  flex-direction: column;
  // color: rgb(83, 39, 39);
}

.btn-icon {
  // margin-left: 10px;
  padding-right: 15px;
}
// .landing-page__cta1 {
//   opacity: 0.8;

//   // background: #fec200;
//   @media screen and (max-width: 700px) {
//     margin-left: 60px;
//   }

//   margin-bottom: 20px;
// }
.landing-page__cta1:hover {
  background: #26ab7f;
}
.landing-page__cta2 {
  opacity: 0.8;

  margin-left: 60px;
}
.landing-page__cta2:hover {
  background: #26ab7f;
}

.banner__icons {
  padding-right: 5px;
  color: rgb(64, 184, 3);
}

// .landing-page__sub-heading li {
//   padding: 10px;
//   // font-size: 18px;
//   margin-top: 70%;
// }
.news__row {
  display: flex;
  justify-content: center;
  align-content: center;

  // padding-left: 100px;
  // padding-right: 100px;
  flex-direction: row;

  // flex-direction: rpw;
  // justify-content: space-between;
  // flex-wrap: wrap;
  // background-color: white;
}
.r4__partners {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: wrap;
}

.product__tabs {
  padding-left: 20px;
  padding-right: 10px;
  display: inline-block;
  // height: 100%;
  // overflow: auto;
}
.tabs__robot {
  padding-left: 20px;
  padding-right: 10px;
  display: inline-block;
  // height: 100%;
}

.features__tab h4 {
  text-align: left;
}
.features__ul li {
  padding: 10px;
  margin: 10px;
}
.features__ul span {
}
.software__cta {
  // flex-direction: row-reverse;
  float: right;
  // background: #fec200;
}
.robot__cta {
  float: right;
  margin: 10px;
  // background-color: #fec200;
}
hr.dash {
  margin: 30px 0;
  height: auto;
  border: none;
  line-height: 0;
}
hr.dash:before {
  content: "";
  height: auto;
  display: inline-block;
  width: 80px;
  height: 10px;
  background-color:#4C4847;
  border-radius: 2.5px;
}

.software__cta:hover {
  background: #26ab7f;
}
.robot__cta:hover {
  background: #26ab7f;
}

.cta-banner {
  z-index: 5;
}


.r2__qoute {
  // padding-left: 60px;
  // padding-right: 60px;
  // padding-bottom: 30px;
  @include problem-background;

  // text-align: center;
  // font-size: 20px;
  // line-height: 1.4em;
  min-height: 200px;
  color: rgb(252, 250, 250);
}
.yield {
  @include yield-background;
  min-height: 240px;
  // color: rgb(252, 250, 250);
  margin-bottom: $spacing-05;
  background-color: #F2F2F2;
}
.problem__section{
  margin-bottom: $spacing-05;
  background-color: #F2F2F2;

}
.probs {
  padding-left: $spacing-05;
  padding-right: $spacing-05;
  padding-top: $spacing-02;
  // padding-bottom: $spacing-02;
  // background-color:rgba($color: #000000, $alpha: 0.5);

  // background-position:inherit;

  color: aliceblue;
  // min-height: 200px;
  text-align: center;
}

.title_qoute{
  font-size: 16px;
  font-weight: 300;
  margin-top: 2%;
  // color: #747474;
}
.prob-open {
  padding-left: $spacing-09;
  padding-right: $spacing-09;
  padding-top: $spacing-07;
  padding-bottom: $spacing-07;

  background-color:rgba($color: #F2F2F2, $alpha: 1);
  

  // background-position:inherit;
  
  color: rgb(7, 7, 7);
  // min-height: 200px;
  // text-align: center;
  // line-height: 2;
  font-size: 28px;
  font-weight: 700;
}

.head-prob {
  // background-color: rgba($color: #f7f0f0, $alpha: 0.5);
  padding: 20px;
  // margin: 40px;
  color: black;
}

.features {
  min-height: 220px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;
  margin-bottom: $spacing-05;
  background-color: #F2F2F2;
}

.trap1 {
  padding-left: $spacing-07 * 7;
  padding-right: $spacing-07 * 7;
  padding-top: $spacing-07;
  background-color: rgb(90, 90, 90);
  background-position: inherit;
  font-size: 20px;
  color: aliceblue;
  min-height: 200px;
}
.trap1 h2 {
  text-align: center;
}
.technology h2 {
  padding-bottom: 100px;
  text-align: center;
}
.technology a {
  padding: 20px;
}
.technology {
  // background-image: url("../../assets/bgs/RenderC4D.png");
  left: 0;
  font-size: 20px;
  color: aliceblue;
  font-size: 30px;
  padding-top: $spacing-07;
  // background-size:cover;
  // width: auto;
  // min-height: 620px;
  // z-index: -1;
  // position: relative;
  min-height: 645px;
  width: auto;
  max-width: 100%;
  display: block;
  margin: auto;
  padding-bottom: 30px;
}
.trap2 {
  padding-left: $spacing-07 * 7;
  padding-right: $spacing-07 * 7;
  padding-top: $spacing-07;
  background-color: rgb(75, 74, 74);
  background-position: inherit;
  font-size: 20px;
  color: aliceblue;
  // min-height: 200px;
}
.cta-hero {
  margin-left: 0;
  // margin-top: 10px;
  // margin-bottom: 32px;
}
.start__heading__feature {
  // padding: 80px;
  font-size: $spacing-08;
  text-align: center;
}
.feature__head {
  padding-bottom: 10px;
}

.feature__subhead {
  padding-bottom: 5px;
}
.feature1__image {
  width: 100%;
  height: auto;
}

.ceo__qoute {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  line-height: 1.4em;
}

.bleed{
  // padding-left: 20px;
  // padding-right: 20px;
  // max-height: 300px;
  // height: 100%;
  // min-width: 100%;
  margin: 0;
  padding: 0px;
}
.f3_decision {
  // margin-top: 20px;
  margin-bottom: $spacing-05;
  background-color: #F2F2F2;
}

.iconset {
  height: 100px;
  // width: auto;
  display: block;
  margin: auto;
}

.charts {
  min-height: 400px;
  // align-items: center;
  padding-left: $spacing-02;
  padding-right: $spacing-02;
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
  min-width: 100%;

}
.charts-bg {
  // background-color: white;
}

.heading-opening-feature {
  color: #f7f0f0;
  padding: 90px;
  text-align: center;
}

.heading-opening-feature h1 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: rgba($color: #000000, $alpha: 0.3);
  padding-left: 20%;
  padding-right: 20%;
font-weight: 600;
  text-align: center;
  // margin: 60px;
}
.glasshouse{
  // background-image: url(../../assets/imgs/agf.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100%;
}
.feature__head_real {
  text-align: center;
  padding-bottom: 20px;
  font-weight: 800;
  font-size: 42px;
  line-height: 64px;
}
p.Parags{
font-size: 20px;
font-weight: 400;  
}
.align--left{
  text-align: left;

}
.elim__f1{
  text-align: center;
  padding-bottom: 20px;


}
.feature__subhead_real {
  text-align: center;
  padding-right: $spacing-05;
  padding-left: $spacing-05;
  font-weight: 500;
}

.bg_yield {
  background-color: black;
}
.feature__mini_head {
  font-weight: 700;
  // color: #050505;
  letter-spacing: 0.8px;
  font-size: 16px;
  line-height: 18px;
  // padding-top: $spacing-09*4;
  padding-bottom: $spacing-02;
}

@media screen and (max-width: 600px) {
  .landing-page__heading{
    // background-color: black;
  }
  .overlay {
    background:rgba(32, 38, 57,0.5) ;
    // background: linear-gradient(100.1deg, rgba(32, 38, 57,0.4) 31.4%, rgba(63, 119, 87, 0.9) 70.2%);  z-index: 3;
    }
  .hero-video{
    display: none;
  }
  .hero-video2{
width: 100%;
height: auto;
z-index: -2;
  }
  video.hero-video2{
    content: "";
    // width: 100%;
    // margin-left:0;
    position: relative;
    // width: auto;
    // min-width: 100%;
    height: auto;
    min-height: 100%;
    background: transparent  no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .feat--ttl--form{
    font-size: 30px;
  font-weight: 600;
  // text-align: center;
  padding-left: 3%;
  line-height: 44.5px; 
  padding-top: 3%;
  // padding-left: 10%;
  padding-bottom: 3%;
  letter-spacing: 0px;
  // background-color: #F2F2F2;
  // padding: 10%;
  }
  div.bx--grid.contact--banner--grow{
    margin-bottom: 5%;
  }
  .title_qoute{
    // padding-top:10%;
    // padding-bottom: 5%;
  }
  .upsidedown{
    font-size: 10px;
    width: 20px;
    height: 20px;
  }
  .normalquote{
    width: 20px;
    height: 20px;
  }
  .landing-page__heading{
    padding-top: 90%;
    font-size: 50px;
    padding-left: 5%;
    
  }
  button.learn--btn.bx--btn.bx--btn--tertiary{
    margin-top:10%;
}
p.ttl--problem{
  padding: 3%;
  font-size: 35px;
  padding-right: 0;
  // text-align: center;
}
.parag--txt{
  padding: 3%;
}

.solu-header{
  font-size: 37px;
  font-weight: 800;
  margin-top: 18px;
  padding-left: 3%;
  // text-align: center;
  
}
.solu-mini{
  margin-top: 10%;
  padding-left: 3%;
  color: #625e5d;
}
.parag--solu{
  color: #625f5f;
  padding-left: 3%;
  // padding-right: 20%;
  padding-top: 8%;
  font-size: 25px;
  font-weight: 300;
  line-height: 33px;
}
p.parag--txt{
  font-size: 20px;
}

.prob--img {
  display: block;
    margin-left: auto;
    margin-right: auto;
}
.cta__book{
  font-size: 20px;
  font-weight: 700;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 4%;
  padding-bottom: 5%;
  // text-align: center;
  
}
.feat--book{
  // text-align: center;
  padding-left: 3%;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.4px;
  }
  .feat--btn{
    margin: 0%;
    margin-top: 5%;
    // left:0;
  }
  .bg__small{
    min-height: 50px;
  }



p.feature__mini_head.solu-mini{
  // color: rgb(17, 14, 14);
  font-weight: 400;
}
.heading-opening-feature h1{
  font-size: 20px;
  padding-left: 5%;
  padding-right: 5%;

}

.heading-opening-feature{
padding: 0%;
}

.feat--ttl{
  padding: 3%;
  font-size: 37px;
  font-weight: 600;

}

.feat--parag{
  padding: 3%;
  font-size: 16px;
}

.cta__book{
  text-align: left;
}
.bx--grid.contact--banner--grow{
  min-height: 0px;
  margin-bottom: 2%;
}
.titl--desk{
  color: #F2F2F2;
  font-size: 37px;
  font-weight:600 ;
  line-height: 44.5px;
  // text-align: center;
  // margin-top: 3%;
  padding-left: 3%;
  padding-top: 5%;
  
  }
  
  .decision--sec{
    padding-top: 3%;
    padding-right: 3%;
    background-color: #032a1b;
  
  }
.bg__small{
  // background-color: #15680fda;
  min-height: 200px;
  background-image: url("../../assets/bgs/robotbg-mobile.webp");
  
  color: #F2F2F2;    
        
    //  margin-top: 10px;
      
  /* Position and center the image to scale nicely on all screens */
  background-repeat: no-repeat;
  background-size: cover;
  //position: relative;
  right: 0;
  min-width: 100%;
  }
  .landing-page__subheading{
    padding-top: 60%;
    font-size: 40px;
  }
  .feature__mini_head {
    font-weight: 100;
    // color: #050505;
    padding-top: $spacing-03;
    padding-bottom: $spacing-02;
  }
}
.feature__head_yield {
  color: white;
  font-weight: 800;
  padding-bottom: $spacing-09;
}
.feature__subhead_yield {
  color: white;
}
.t-yield {
  color: white;
  
}

.mobilephone {
  padding-left: $spacing-08 ;
  padding-top: $spacing-03 ;

  padding-bottom: 1px;
}
p.cta__black_mobile{
font-weight: 300;
font-size: 22px;
}

.bx--accordion{
  margin-top: $spacing-09;
}

.campaign{
  color: #BAFF25;
  font-weight: 100;

}
.bx--accordion__title{
  color: #fbfcfa;
  font-weight: 500;
  font-size: 22px;
  font-style: initial;
  padding: $spacing-05;
  margin-bottom: 10px;
  

}
.bx--accordion__heading:hover::before{
  background-color: black;
}
.bx--accordion__item .bx--accordion__arrow {
fill: white;
}

.cta_plantsese{
  color: white;
  // font-weight: 800;

}
.bx--accordion__heading {
  // background-color: black;
  // color: white;
}


.f3__iot{
  padding-left: $spacing-09*2;
  padding-right: $spacing-09*2;
  // padding-top: $spacing-09;
  padding-bottom: $spacing-09;



}

.feature__head__human{
  font-weight: 800;

}

.slideshow{
  min-height: 300px;
}

// .video-tech {
//   height: 100%;
// }
.hero-tech {
  width: 100%;
  margin-left: 0;
  z-index: -100;
}

.heroVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%; 
  height: 100%;
}

.bg--vision{
// background-image: url("../../assets/imgs/handofgod.webp");
background-size: cover;
// min-height: 600px;

}


button.CTA2 {
  // border:red
  // border-color:#8DFF00;
  border-color:#f9faf8;
  color:white;
  font-size:15px;
  font-weight: 600;
}
button.technology-cta{
  border-color:#8DFF00;
background-color: #09984d;
font-size: 15px;
font-weight: 800;
}
// .image-skout-sec{
//   background-image: url(../../assets/imgs/Skout05.webp);
//   background-repeat: no-repeat;
//   background-size: cover;
//   max-width: 100%;
//   height: auto;
// }
.vps{
  // max-height: 100%;
  min-height: 220px;
  z-index: -3;
  width: 100%;
}
.rect{
  min-height: 140px;
  z-index: -3;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: white;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20%;

}
.rect1{
  background-color: #07162f;

}
.rect2{
  background-color:#07162f;

}
.sub-paraf{
padding-top:2% ;  

}

.vps--ttl:hover::after{
  display: block;

}

.vps--ttl{
  display: none;
  // position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background-color: #8e84847e;
}
// .solu-header{
//   font-size: 57px;
//   font-weight: 800;
//   // text-align: center;
//   margin-top: 18px;
//   padding-left: 20%;
//   // padding-right: 20%;
//   // color:white
  

// }

.sl--header{
  background: #F2F2F2;
  padding-bottom: 4%;
}


.sharp-img{
  padding-left: 0px;
  padding-right: 0px;


}













.feat--btn{
text-align: center;
}




.bg--team{
  background-image: url("../../assets/imgs/home-contact-img.webp");
  background-repeat: no-repeat;
background-size: cover;
}
.formgrid{
  margin-top: 1%;
}
@keyframes AnimateBG { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
.bg-desk{
// background-image: url("../../assets/bgs/bg-bl-green.webp");
// background-color: #000000;
margin-right: 0%;
padding-right: 0%;
margin-top: 10px;
background-repeat: no-repeat;
background-size: cover;
min-width: 100%;

background: linear-gradient(35deg, #09203f, #035133, #037a2a, #477e62);
	background-size: 400% 400%;
	animation: gradient 20s ease infinite;
	// height: 100vh;

}
@keyframes gradient {
	0% {
		background-position: 0% 30%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}



.desktop--img{
// margin-right: 0%;
// padding-right: 0%;
// position: relative;
// left: 299px;
// top:5%;
width: auto;
min-height: 100%;
// object-fit: cover;
padding-left: 0px;
padding-right: 0px;

// margin: 0px;
}
div.bx--col-lg-8.greehousetom{
  padding: 0px;
}

.numbers--data{
color: #F2F2F2;
padding-left: 20%;
text-align: center;
font-size: 50px;
font-weight: 600;
}

.numbers--ttl{
  font-size: 80px;
}


button.bx--btn{

font-size: 15px;
font-weight:500;
}

button.bx--btn:hover{

}

.learn--btn{
border-color: white;
color: rgb(246, 243, 243);
// background-color: lightgray;
}

.ttl--problem{
  font-size: 80px;
  font-weight: 600;
  padding-left: 30%;
  padding-top: 10px;
}



.parag--txt{
  font-size: 25px;
  font-weight: 200;
  padding-bottom: 3%;
}

.prob--sec{
  margin-top: 20px;
  margin-bottom: 20px;
}

.probs img{
  border-radius: 50%;

}

.upsidedown{
  -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.solution--big{

  font-size: 80px;
  font-weight: 600;
  // text-align: center;
  min-height: 300px;
  // background-color: #000000;
  // color: white;


}

.solution--big h1{
// text-align: center;
}

.btn--vision{
  // background-color:#037a2a
  color:white;
  border-color: white;
}

button.btn--vision.bx--btn.bx--btn--tertiary:hover{
  background-color: #037a2a;
}

section.bx--row.value--prop.sl--header.info-section{
 padding-left: 10%;
}

.robo--360-section{
  margin-bottom: 2%;
}

.features--section{
margin-bottom: 5%;
}

.feat--btn{
  background-color:#099A4D;
}

.feat--btn:hover{
  background-color:#066f37;
}

.twitter--feeds{
background-color: #F2F2F2;
margin-top: 3%;
margin-bottom: 3%;
}

.last--sec{
  margin: 5%;
  min-height: 100px;
}

