@import "./_mixins.scss";

.career__heading {
  padding: 60px;
  text-align: center;
  font-family: 600;
}
.career {
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-wrap: wrap;

  // padding-left: 100px;
  // padding-right: 100px;
  flex-direction: row;
  margin: 0;
  justify-content: center;
}


@mixin career-page-background() {
  // background: #00c9ff;
  // background-image: linear-gradient(
  //   225deg,
  //   #34e89e 0%,
  //   #0f3443 100%,
  //   #625eb1 100%
  // );
  background-image: url("../../assets/imgs/careerbanner.webp");
  background-size: cover;
  
  
  // height: cover;

  // // display: block;
  // &::before {
  //   content: "";
  //   position: fixed;
  //   // left: -$spacing-05;
  //   top: 0;
  //   // right: -$spacing-05;
  //   bottom: 0;
  //   background: $ui-03;
  //   z-index: -2;
  //   // opacity: 70%;

  // }
}
.career-page__banner {
  @include career-page-background;
  // background-color: rgb(4, 65, 4);
  min-height: 600px;
padding: 0;
// margin: 0;
min-width: 0;
overflow: hidden;

// padding: 0px;
max-width: 100%;
max-height: 100%;
// min-height: 700px;
font-weight: 800;
  text-align: center;
    color:white;
    padding-top: 160px;

}

@media screen and (min-width: 1400px) {
  .career-page__banner {
    min-height:900px;
  }
}
@media screen and (min-width: 1600px) {
  .career-page__banner {
    min-height: 900px;

  }
}
@media screen and (min-width: 1900px) {
  .career-page__banner {
    min-height: 900px;

  }
}

.mini-career-title{
  font-size: 20px;
  margin-top: 104px;
  color:#BAFF25 ;  
  font-weight: 800;
  text-align: center;
}


.carreers--items{
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 10%;
  margin-bottom: 10%;

}

.bx--accordion__title{
  color: black;
  font-weight: 400;
}

.bx--accordion__title:hover::before {
  color: white;
}
button.bx--accordion__heading{
  color: rgb(8, 8, 8);
  background-color: white;
}

.bx--accordion__heading:hover::before {
  background-color: rgba(85, 87, 85, 0.379);
}

.bx--accordion__item .bx--accordion__arrow{
  fill:black
}

.bx--accordion{
  border-color: black;
}
.bx--accordion__heading:focus::before{
  border-color: rgba(0, 128, 0, 0);
}

.bx--accordion__heading{
border: 2px solid black;
}