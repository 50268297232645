img {
  max-width: 100%;
  max-height: 80%;
  display: block; /* remove extra space below image */
  text-align: center;
  display: block;
  overflow: hidden;
}
.fresh__heading {
  padding-bottom: 20px;
}
